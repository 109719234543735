<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-01-13 15:32:40
 * @LastEditors: hutian
 * @LastEditTime: 2021-01-31 18:35:57
-->
<template>
  <div>
    <div>
      <a-button type="primary" @click="toHanlder('add')">新增字典</a-button>
    </div>
    <a-table :rowKey="record=>record.id" :columns="columns" :data-source="dicList" bordered :pagination="false">
      <span slot="flagEnable" slot-scope="text">{{text?"在用":"停用"}}</span>
      <span slot="action" slot-scope="text, record">
        <a @click="toHanlder('edit',record)">编辑</a>
        <a-divider type="vertical" />
        <a @click="toHanlder('detail',record)">
          <a-icon type="bars" />字典详情</a>
        <a-divider type="vertical" />
        <a @click="toHanlder('del',record)">删除</a>
      </span>
    </a-table>
    <edit-dict-modal ref="editDict" @reload="toSearch"></edit-dict-modal>
    <dic-detail-modal ref="dicDetail"></dic-detail-modal>
  </div>
</template>

<script>
  import {
    dicColumns
  } from "./components/columns.js"
  import editDictModal from "./components/EditDictModal.vue"
  import dicDetailModal from "./components/DictDetailModal.vue"
  export default {
    data() {
      return {
        dicList: [],
        parentDicList: [
          {
            id: 1,
            label: '通用字典'
          }
        ],
        columns: dicColumns
      }
    },
    components: {
      editDictModal,
      dicDetailModal
    },
    methods: {
      /**
       * 获取字典列表（包括父字典）
       */
      toSearch() {
        const that = this
        that.axios.get(`/api/base/system/dictionary/list`).then(res => {
          // let tmpArr = []
          // let parentArr = []
          // res.body.records.forEach(x => {
          //   parentArr.push({
          //     value: x.id,
          //     label: x.fullName
          //   })
          //   if (x.children.length > 0) {
          //     tmpArr.push(x.children)
          //   }
          // })
          // this.dicList = tmpArr.flat()
          // this.parentDicList = parentArr
          that.dicList = res.body.records
        }).catch(err => {})
      },
      /**
       * 编辑字典
       */
      toHanlder(handle, record) {
        if (handle == 'add') {
          this.$refs.editDict.showEditDict(this.parentDicList, 'add')
        } else if (handle == 'edit') {
          this.$refs.editDict.showEditDict(this.parentDicList, 'edit', record);
        } else if (handle == 'del') {
          this.$confirm({
            title: '警告',
            content: `真的删除该字典吗?`,
            okType: 'danger',
            onOk: () => {
              this.axios.post(`/api/base/system/dictionary/del/${record.id}`).then(res => {
                this.$notification.success({message: res.message})
                this.toSearch()
              }).catch(err => {})
            }
          })
        } else if (handle == 'detail') {
          this.$refs.dicDetail.showEditDict(record);
        }
      }
    },
    // },
    created() {
      this.toSearch()
    }
  }
</script>

<style lang="less" scoped>
</style>
